import React from 'react';

const ErrorPage = () => {
  return (
    <h1>
      에러가 발생했습니다. 페이지를 찾을 수 없습니다.
    </h1>
  );
};

export default ErrorPage;